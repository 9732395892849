<template>
  <b-card>
    <b-nav pills>
      <group-nav-item
        :route="`/groups/${groupId}/summary`"
        icon="CompassIcon"
        title="Sommaire"
      />
      <group-nav-item
        :route="`/groups/${groupId}/itineraries`"
        icon="MapIcon"
        title="Itinéraire"
      />
      <group-nav-item
        :route="`/groups/${groupId}/reservations`"
        icon="ClipboardIcon"
        title="Réservations"
        :exact="false"
      />
      <group-nav-item
        :route="`/groups/${groupId}/inventory`"
        icon="TrendingUpIcon"
        title="Inventaires"
      />
      <group-nav-item
        :route="`/groups/${groupId}/passengers`"
        icon="ListIcon"
        title="Liste passagers"
      />
      <group-nav-item
      v-if="canViewBasedOnRole(this.CURRENT_ROLE, [this.Roles.ORGANIZATION_ADMIN.role, this.Roles.ORGANIZATION_COORDINATOR.role])"
        :route="`/groups/${groupId}/details`"
        :exact="false"
        icon="InfoIcon"
        title="Détails du groupe"
      />
      <group-nav-item
        v-if="canViewBasedOnRole(this.CURRENT_ROLE, [this.Roles.ORGANIZATION_ADMIN.role, this.Roles.ORGANIZATION_COORDINATOR.role])"
        :route="`/groups/${groupId}/packages`"
        icon="ShoppingBagIcon"
        title="Forfaits"
      />
      <group-nav-item
        v-if="
          canViewBasedOnRole(this.CURRENT_ROLE, [
            this.Roles.ORGANIZATION_ADMIN.role,
            this.Roles.ORGANIZATION_COORDINATOR.role,
          ]) && showAdditionalOptions
        "
        :route="`/groups/${groupId}/additional-options`"
        icon="ShoppingBagIcon"
        title="Options supplémentaires"
      />
      <group-nav-item
        :route="`/groups/${groupId}/billing`"
        :exact="false"
        icon="DollarSignIcon"
        title="Facturation"
      />
      <group-nav-item
        :route="`/groups/${groupId}/documents`"
        icon="FileIcon"
        title="Documents"
      />
    </b-nav>

    <slot />
  </b-card>
</template>

<script>
import { BCard, BNav } from "bootstrap-vue";
import GroupNavItem from "@/modules/group/components/GroupNavItem.vue";
import Roles from "@/modules/authnz/models/role";
import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Tenant from "@/plugins/tenant";

export default {
  components: {
    BCard,
    BNav,
    GroupNavItem,
  },
  data() {
    return {
      CURRENT_ROLE: null,
      Roles,
    };
  },
  created() {
    this.CURRENT_ROLE =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
    showAdditionalOptions() {
      return this.$store.state["app-auth"]?.orgSettings
        ?.additional_options_enable;
    },
  },
  methods: {
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    },
  },
};
</script>
