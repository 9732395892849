<template>
  <group-navigation>
    <router-view></router-view>
  </group-navigation>
</template>

<script>
import GroupNavigation from "@/modules/group/components/GroupNavigation.vue";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import mixpanel from "mixpanel-browser";

import { GroupService } from "../services/GroupService.js";
export const groupService = new GroupService();

import { GroupBilling } from "../services/GroupBilling.js";
export const groupBilling = new GroupBilling();

import { GroupPackagesService } from "../services/GroupPackagesService.js";
export const groupPackagesService = new GroupPackagesService();

import { GroupItineraryService } from "../services/GroupItineraryService.js";
export const groupItineraryService = new GroupItineraryService();

import { GroupAdditionalOptionService } from "../services/GroupAdditionalOptionService.js";
export const additionalOptionService = new GroupAdditionalOptionService();

import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { useLocalisation } from "@/shared/composables/use-localisation";

export default {
  components: {
    GroupNavigation,
  },
  setup() {
    const { t } = useLocalisation();
    const { getDocuments, deleteDocument } = useGroupApi();

    return {
      t,
      getDocuments,
      deleteDocument,
    };
  },
  created() {
    this.CURRENT_ROLE =
      this.$store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
    this.currentGroupId = this.$route.params.id;

    this.$store.commit("app-groups/clearGroup");

    return new Promise((resolve, reject) => {
      groupService
        .getGroup(this.currentGroupId)
        .then((response) => {
          this.currentGroup = response;

          this.$store.commit("app-groups/setCurrentGroup", this.currentGroup);
          this.$store.commit(
            "app-groups/setCurrentGroupName",
            this.currentGroup.name
          );
          this.$store.commit(
            "app-groups/setCurrentGroupId",
            this.currentGroup.id
          );

          this.show = false;
          mixpanel.track("Access group", {
            group: response,
          });

          return groupService.getTransportations(this.currentGroupId);
        })
        .then((response) => {
          this.currentTransportations = response.data;

          this.$store.commit(
            "app-groups/setTransportations",
            this.currentTransportations
          );

          return groupService.getAccommodations(this.currentGroupId);
        })
        .then((response) => {
          this.currentAccommodations = response.data;

          this.$store.commit(
            "app-groups/setAccommodations",
            this.currentAccommodations
          );

          return groupPackagesService.getPackages(this.currentGroupId);
        })
        .then((response) => {
          this.currentPackages = response.data;

          this.$store.commit("app-groups/setPackages", this.currentPackages);

          return groupItineraryService.getItinerary(this.currentGroupId);
        })
        .then((response) => {
          this.currentItinerary = response.data;

          this.$store.commit(
            "app-groups/setItineraries",
            this.currentItinerary
          );

          return this.getDocuments(this.currentGroupId);
        })
        .then((response) => {
          this.documents = response;

          this.$store.commit("app-groups/setDocuments", this.documents);
          return groupBilling.getAccounting(this.currentGroupId);
        })
        .then((response) => {
          this.accounting = response.data;

          this.$store.commit("app-groups/setAccounting", this.accounting);

          if (
            this.$store.state["app-auth"]?.orgSettings
              ?.additional_options_enable
          ) {
            return additionalOptionService.getGroupAdditionalOptions(
              this.currentGroupId
            );
          }
        })
        .then((response) => {
          if (
            this.$store.state["app-auth"]?.orgSettings
              ?.additional_options_enable
          ) {
            this.additionalOptions = response.data;

            this.$store.commit(
              "app-groups/setAdditionalOptions",
              this.additionalOptions
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
</script>
